import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

export function initSentry(): void {
  Sentry.init({
    dsn: 'https://198631bfee49d231e81acdcdc207b2bc@o4507094954606592.ingest.de.sentry.io/4507094955851856',
    integrations: [],
  });
}

export function provideSentry() {
  return [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ];
}
