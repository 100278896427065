import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { provideState, provideStore } from '@ngrx/store';
import { authReducers } from '@core/states/auth/auth.reducers';
import { provideEffects } from '@ngrx/effects';
import { AuthEffects } from '@core/states/auth/auth.effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { environment } from '@environment/environment';
import { provideAppInterceptors } from './app.route-providers';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideSentry } from 'src/sentry';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(APP_ROUTES, withComponentInputBinding()),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore(),
    provideState({ name: 'auth', reducer: authReducers }),
    provideEffects(AuthEffects),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production }),
    provideCharts(withDefaultRegisterables()),
    ...provideAppInterceptors(),
    ...provideSentry(),
    importProvidersFrom(MatSnackBarModule, MatDialogModule),
  ],
};
