import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { HasRoleDirective } from '@core/directives/has-role.directive';
import { Role } from '@core/states/auth/enums/role';
import {
  FaIconComponent,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faBookmark,
  faChartLine,
  faCode,
  faCodeFork,
  faDiagramProject,
  faFingerprint,
  faGaugeHigh,
  faLock,
  faPuzzlePiece,
  faRocket,
  faScrewdriver,
  faSpaceShuttle,
  faTerminal,
  faUser,
  faUserAstronaut,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { GorillaSvgComponent } from '@shared/components/vectors/gorilla-svg/gorilla-svg.component';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { IsFeatureEnabledDirective } from '@core/feature-preview/directives/is-feature-enabled.directive';

@Component({
  standalone: true,
  selector: 'app-sidebar',
  imports: [
    CommonModule,
    SidebarItemComponent,
    GorillaSvgComponent,
    RouterLink,
    HasRoleDirective,
    FaIconComponent,
    IsFeatureEnabledDirective,
  ],
  styles: [
    ':host { @apply relative z-50 flex flex-col overflow-visible shadow; } ',
  ],
  template: `
    <aside
      class="bg--primary h-screen w-full min-w-72 overflow-x-hidden"
      aria-label="Sidebar">
      <div class="border--default relative h-full border-r px-3">
        <div class="border--default ml-2 flex h-14 items-center pt-2">
          <a class="cursor-pointer" routerLink="/dashboard">
            <div class="group flex items-center">
              <app-gorilla-svg [size]="50" />
            </div>
          </a>
        </div>
        <div class="flex flex-col gap-y-8 pt-4 font-medium">
          <div class="flex flex-col">
            <div class="text--backdrop pl-2 text-xs font-semibold leading-6">
              Workspace
            </div>
            <ul>
              <app-sidebar-item
                *appIsFeatureEnabled="'Dashboard'"
                [icon]="['fas', 'chart-line']"
                label="Dashboard"
                path="dashboard" />
              <app-sidebar-item
                *appIsFeatureEnabled="'Workflow'"
                [icon]="['fas', 'diagram-project']"
                label="Workflows"
                path="workflows" />
              <app-sidebar-item
                [icon]="['fas', 'code-fork']"
                label="Processes"
                path="process-definitions" />
              <app-sidebar-item
                [icon]="['fas', 'rocket']"
                label="Executed Processes"
                path="process-instances" />
              <app-sidebar-item
                [icon]="['fas', 'fingerprint']"
                label="Triggers"
                path="detection-type-definitions" />
              <app-sidebar-item
                [icon]="['fas', 'space-shuttle']"
                label="Executed Triggers"
                path="detection-instances" />
              <app-sidebar-item
                [icon]="['fas', 'users']"
                label="Tenants"
                path="tenants" />
            </ul>
          </div>

          <div class="flex flex-col">
            <div class="text--backdrop pl-2 text-xs font-semibold leading-6">
              Administration
            </div>
            <ul>
              <app-sidebar-item
                [icon]="['fas', 'user']"
                label="Users"
                path="users" />
            </ul>
          </div>

          <div class="flex flex-col" *appHasRole="[Role.ROLE_SUPER_ADMIN]">
            <div
              class="text--backdrop flex gap-2 pl-2 text-xs font-semibold leading-6">
              <span>Staff</span>
              <fa-icon [icon]="'lock'" class="text-xs"></fa-icon>
            </div>
            <ul>
              <app-sidebar-item
                [icon]="['fas', 'user-astronaut']"
                label="App Configuration"
                path="application-configurations" />
              <app-sidebar-item
                [icon]="['fas', 'puzzle-piece']"
                label="Components"
                path="component-definitions" />
              <app-sidebar-item
                [icon]="['fas', 'bookmark']"
                label="Tags"
                path="tags" />
              <app-sidebar-item
                [icon]="['fas', 'gauge-high']"
                label="Process Throttles"
                path="process-throttles" />
            </ul>
          </div>
        </div>
      </div>
    </aside>
  `,
})
export class SidebarComponent {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faBookmark,
      faCodeFork,
      faRocket,
      faGaugeHigh,
      faFingerprint,
      faSpaceShuttle,
      faUsers,
      faUser,
      faScrewdriver,
      faTerminal,
      faCode,
      faUserAstronaut,
      faPuzzlePiece,
      faChartLine,
      faLock,
      faDiagramProject
    );
  }

  protected readonly Role = Role;
}
