import { Component, ViewChild } from '@angular/core';
import { NavMenuTriggerComponent } from '@core/components/navbar/nav-menu/nav-menu-trigger.component';
import { NavMenuContentComponent } from '@core/components/navbar/nav-menu/nav-menu-content.component';

@Component({
  standalone: true,
  selector: 'app-nav-menu',
  imports: [NavMenuTriggerComponent, NavMenuContentComponent],
  template: `
    <div class="relative z-[100] flex h-full items-center">
      <app-nav-menu-trigger class="mt-2" #menuTrigger />

      @if (menuTrigger.isOpen()) {
        <app-nav-menu-content />
      }
    </div>
  `,
})
export class NavMenuComponent {
  @ViewChild('menuTrigger') menuTrigger: NavMenuTriggerComponent;
}
