import { Component } from '@angular/core';
import { ParticleComponent } from './particle.component';
import { CommonModule } from '@angular/common';
import { RandomSizeDirective } from '@shared/directives/random-size.directive';

@Component({
  standalone: true,
  selector: 'app-particles',
  template: `
    <div class="particles-container ">
      <ng-container *ngFor="let particle of particles; let i = index">
        <app-particle appRandomSize> </app-particle>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .particles-container {
        position: absolute;
        z-index: 20;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
      }
    `,
  ],
  imports: [ParticleComponent, CommonModule, RandomSizeDirective],
})
export class ParticlesComponent {
  particles = Array.from({ length: 200 });

  constructor() {}

  randomDuration(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
