<section
  class="bg--primary flow relative flex h-full w-full flex-col items-center justify-center gap-y-12 overflow-clip">
  <div class="absolute inset-0 z-10 animate-pulse-slow bg-radial-primary"></div>
  <app-particles />

  <div class="flex flex-col items-center font-smartflow">
    <app-gorilla-svg [size]="92" [addGroup]="true" />
    <h1
      class="text--contrast-lightest text-4xl font-semibold leading-none tracking-tight md:text-5xl lg:text-6xl">
      FLOWZILLA
    </h1>
    <span class="text--backdrop text-base">powered by group24</span>
  </div>

  <div class="z-30 w-96">
    <ng-content></ng-content>
  </div>
</section>
