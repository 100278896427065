import { FeatureKey } from '@core/feature-preview/types/feature-key';

export const enableFeature = (key: FeatureKey): void => {
  const item = localStorage.getItem(key);

  if (item) {
    return;
  }

  localStorage.setItem(key, 'enabled');
};
