import { AuthUser } from '@core/states/auth/interfaces/auth-user';
import { LoadStatus } from '@core/states/auth/interfaces/load-status';

export interface AuthState {
  loadStatus: LoadStatus;
  error: Error | undefined;
  user: AuthUser | undefined;
}

export const initialAuthState: AuthState = {
  loadStatus: LoadStatus.NOT_LOADED,
  error: undefined,
  user: undefined,
};
