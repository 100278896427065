import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { LayoutService } from '@core/services/layout.service';
import { Layout } from '@core/enums/layout';
import { LayoutAdminComponent } from '@core/components/layouts/layout-admin/layout-admin.component';
import { LayoutDefaultComponent } from '@core/components/layouts/layout-default/layout-default.component';
import { LayoutAuthComponent } from '@core/components/layouts/layout-auth/layout-auth.component';
import { BreadcrumbService } from '@core/services/breadcrumb.service';
import { ThemeService } from '@core/services/theme.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faArrowDown,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleQuestion,
  faCodeFork,
  faCompress,
  faCubes,
  faExpand,
  faEye,
  faEyeSlash,
  faFaceMeh,
  faFileCode,
  faFingerprint,
  faMagnifyingGlass,
  faMinus,
  faPencil,
  faPlus,
  faQuestion,
  faRocket,
  faSpaceShuttle,
  faSpinner,
  faTrash,
  faUpDown,
  faWrench,
  faXmark,
  faFloppyDisk,
  faScrewdriverWrench,
  faSliders,
  faHammer,
  faMessage,
  faGear,
  faAngleRight,
  faArrowRightFromBracket,
  faAngleLeft,
  faChevronUp,
  faFlask,
  faCheck,
  faUser,
  faShieldHalved,
  faFileContract,
  faAddressCard,
  faCircleCheck,
  faCircleXmark,
  faCircleExclamation,
  faGaugeHigh,
  faUsers,
  faScrewdriver,
  faTerminal,
  faUserAstronaut,
  faCode,
  faImage,
  faClipboard,
  faClone,
  faArrowRight,
  faPaste,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LayoutAdminComponent,
    LayoutDefaultComponent,
    LayoutAuthComponent,
  ],
  templateUrl: './app.component.html',
})
export class AppComponent {
  readonly Layout = Layout;
  constructor(
    public layoutService: LayoutService,
    private themeService: ThemeService,
    private breadcrumbService: BreadcrumbService, // do not delete! needed for initialization
    library: FaIconLibrary
  ) {
    library.addIcons(
      faXmark,
      faPlus,
      faEye,
      faEyeSlash,
      faCubes,
      faSpaceShuttle,
      faMinus,
      faChevronRight,
      faCompress,
      faExpand,
      faUpDown,
      faPencil,
      faFingerprint,
      faWrench,
      faCircleQuestion,
      faChevronUp,
      faChevronLeft,
      faChevronDown,
      faSpinner,
      faQuestion,
      faRocket,
      faFaceMeh,
      faArrowDown,
      faCodeFork,
      faFileCode,
      faMagnifyingGlass,
      faTrash,
      faFloppyDisk,
      faScrewdriverWrench,
      faSliders,
      faHammer,
      faMessage,
      faGear,
      faAngleRight,
      faAngleLeft,
      faArrowRightFromBracket,
      faFlask,
      faCheck,
      faUser,
      faShieldHalved,
      faFileContract,
      faAddressCard,
      faCircleCheck,
      faCircleXmark,
      faCircleExclamation,
      faGaugeHigh,
      faSpaceShuttle,
      faUsers,
      faScrewdriver,
      faTerminal,
      faUserAstronaut,
      faCode,
      faImage,
      faClipboard,
      faClone,
      faArrowRight,
      faPaste,
      faWandMagicSparkles
    );
  }
}
