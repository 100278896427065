<nav class="ml-2 flex justify-between" aria-label="Breadcrumb">
  <ol class="inline-flex items-center">
    <li *ngFor="let breadcrumb of breadcrumbs$ | async; let last = last">
      <div class="flex items-center">
        <a
          [routerLink]="breadcrumb.url"
          class="transistion-all inline-flex cursor-pointer items-center rounded-lg px-3 py-2 text-center duration-100 hover:bg-primary-50 hover:text-primary-500 hover:ring-1 hover:ring-primary-500 dark:ring-primary-500/80 dark:hover:bg-primary-950">
          {{ breadcrumb.title }}
        </a>
        <span class="mx-2 font-medium text-zinc-500" *ngIf="!last">/</span>
      </div>
    </li>
  </ol>
</nav>
