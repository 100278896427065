import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FeaturePreview } from '@core/feature-preview/types/feature-preview';
import { FeaturePreviewListItemComponent } from '@core/feature-preview/dialog/feature-preview-list-item.component';

@Component({
  standalone: true,
  selector: 'app-feature-preview-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FeaturePreviewListItemComponent],
  template: `
    @for (feature of features(); track $index) {
      <app-feature-preview-list-item
        [feature]="feature"></app-feature-preview-list-item>
    } @empty {
      <p class="text-lg">Currently there are no features in preview.</p>
    }
  `,
})
export class FeaturePreviewListComponent {
  features = input.required<FeaturePreview[]>();
}
