<li class="my-1">
  <a
    class="group flex items-center gap-x-3 rounded-lg px-3 py-2 font-normal transition-all duration-100 hover:bg-primary-50 hover:text-primary-500 hover:ring-1 hover:ring-primary-500 dark:hover:bg-primary-950 dark:hover:ring-primary-500/80"
    routerLink="/{{ path }}"
    routerLinkActive="dark:bg-primary-950 bg-primary-50 text-primary-500 ring-1 border-primary-500 ring-primary-500 dark:border-primary-500/80 hover:ring-0"
    [routerLinkActiveOptions]="{ exact: false }">
    <fa-icon
      routerLinkActive="text-primary-500"
      class="flex items-center justify-center group-hover:text-primary-500"
      [icon]="icon" />
    <span>{{ label }}</span>
  </a>
</li>
