@if (opened !== undefined) {
  <mat-drawer-container class="h-full" autosize>
    <mat-drawer
      class="z-50 shadow"
      #drawer
      [opened]="opened"
      [mode]="drawerMode()!!"
      (closedStart)="onSidenavChange(false)"
      (closed)="onSidenavChanged()"
      (opened)="onSidenavChanged()">
      <app-sidebar></app-sidebar>
    </mat-drawer>

    <mat-drawer-content class="bg--primary">
      <app-navbar
        [sidebarCollapsed]="opened"
        (sidebarToggleEvent)="sidebarCollapsedToggle()"></app-navbar>
      <ng-content></ng-content>
    </mat-drawer-content>
  </mat-drawer-container>
}
