import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Role } from '@core/states/auth/enums/role';
import { Store } from '@ngrx/store';
import {
  selectAuthUserHasRole,
  selectIsLoggedIn,
} from '@core/states/auth/auth.selectors';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { LOGIN_PATH } from '@features/auth/auth.routes';

export const hasRoleGuard = (roles: Role[]): CanActivateFn => {
  return () => {
    const store = inject(Store);
    const router = inject(Router);

    const urlTree = router.createUrlTree([LOGIN_PATH]);
    return store.select(selectIsLoggedIn).pipe(
      filter(status => status),
      switchMap(() => store.select(selectAuthUserHasRole(roles))),
      map(hasRole => (hasRole ? true : urlTree)),
      catchError(() => of(urlTree))
    );
  };
};
