import { FeatureKey } from '@core/feature-preview/types/feature-key';
import { Features } from '@core/feature-preview/features';
import { FeaturePreviewStatus } from '@core/feature-preview/types/feature-preview';

export const isFeatureEnabled = (key: FeatureKey): boolean => {
  if (!Features) {
    return false;
  }
  return (
    (!!localStorage.getItem(key) &&
      Features[key].status === FeaturePreviewStatus.PREVIEW) ||
    Features[key].status === FeaturePreviewStatus.LIVE
  );
};
