import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, signal, ViewChild, WritableSignal } from '@angular/core';
import {
  MatDrawer,
  MatDrawerMode,
  MatSidenavModule,
} from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent } from '@core/components/navbar/navbar.component';
import { SidebarComponent } from '@core/components/sidebar/sidebar.component';
import { SidenavService } from '@core/services/sidenav.service';

@Component({
  selector: 'app-layout-admin',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    NavbarComponent,
    RouterOutlet,
    SidebarComponent,
  ],
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.css'],
})
export class LayoutAdminComponent {
  drawerMode: WritableSignal<MatDrawerMode | undefined> = signal('side');
  opened: boolean;

  @ViewChild('drawer') drawer: MatDrawer | undefined;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private sidenavService: SidenavService
  ) {
    this.sidenavService.sidebarCollapsed$.subscribe(
      collapsed => (this.opened = collapsed)
    );

    this.breakpointObserver
      .observe([
        Breakpoints.HandsetLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
      ])
      .subscribe(result => {
        this.sidenavService.changeDrawerMode(result.matches ? 'over' : 'side');
      });

    this.sidenavService.drawerMode$.subscribe(mode =>
      this.drawerMode.set(mode)
    );
  }

  sidebarCollapsedToggle() {
    this.drawer?.toggle();
    this.sidenavService.setSidebarCollapsed(!this.opened);
  }

  onSidenavChange = (collapsed: boolean) =>
    this.sidenavService.setSidebarCollapsed(collapsed);

  onSidenavChanged = () => this.sidenavService.sidenavChanged();
}
