import { Component, HostBinding } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-particle',
  template: '',
  styles: [
    `
      :host {
        display: block;
        position: absolute;
        border-radius: 50%;
        background-color: var(--color-particle); /* oder eine andere Farbe */
      }
    `,
  ],
})
export class ParticleComponent {
  @HostBinding('style.left.%') left: number;
  @HostBinding('style.top.%') top: number;
  @HostBinding('style.animation') animation: string;

  constructor() {
    this.left = Math.random() * 100;
    this.top = Math.random() * 100;

    const flickerDuration = Math.random() * 5 + 5; // Zwischen 5s und 10s
    const willOWispDuration = Math.random() * 15 + 15; // Zwischen 15s und 30s
    const delay = Math.random() * -10; // Verzögerung bis zu 10 Sekunden rückwärts

    this.animation = `flicker ${flickerDuration}s infinite, will-o-wisp ${willOWispDuration}s infinite ${delay}s`;
  }
}
