import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { GorillaSvgComponent } from '../../../../shared/components/vectors/gorilla-svg/gorilla-svg.component';
import { ParticlesComponent } from '../../../../shared/components/particles/particles.component';

@Component({
  selector: 'app-layout-auth',
  standalone: true,
  templateUrl: './layout-auth.component.html',
  imports: [
    CommonModule,
    RouterOutlet,
    GorillaSvgComponent,
    NgOptimizedImage,
    ParticlesComponent,
  ],
})
export class LayoutAuthComponent {}
