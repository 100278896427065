import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Features } from '@core/feature-preview/features';
import { DialogHeaderComponent } from '@core/components/dialogs/dialog-header.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconButtonComponent } from '@shared/components/buttons/icon-button/icon-button.component';
import { MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { FeaturePreviewStatus } from '@core/feature-preview/types/feature-preview';
import { FeaturePreviewListComponent } from '@core/feature-preview/dialog/feature-preview-list.component';

@Component({
  standalone: true,
  selector: 'app-feature-preview-dialog',
  imports: [
    DialogHeaderComponent,
    FaIconComponent,
    IconButtonComponent,
    MatDialogClose,
    MatDialogContent,
    FeaturePreviewListComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="bg-white dark:bg-zinc-950">
      <app-dialog-header [title]="'Feature preview'">
        <app-icon-button close mat-dialog-close>
          <fa-icon [icon]="['fas', 'xmark']" />
        </app-icon-button>
      </app-dialog-header>
      <mat-dialog-content>
        <app-feature-preview-list
          [features]="features"></app-feature-preview-list>
      </mat-dialog-content>
    </div>
  `,
})
export class FeaturePreviewDialogComponent {
  features = Object.values(Features || {}).filter(
    feature => feature.status === FeaturePreviewStatus.PREVIEW
  );
}
