import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { NavMenuComponent } from '@core/components/navbar/nav-menu/nav-menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ThemeSwitchComponent } from './theme-switch.component';

@Component({
  standalone: true,
  selector: 'app-navbar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BreadcrumbComponent,
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatMenu,
    MatMenuTrigger,
    NavMenuComponent,
    ThemeSwitchComponent,
  ],
  styles: `
    :host {
      @apply relative sticky top-0 z-50 flex flex-col overflow-visible shadow-sm;
    }
  `,
  template: `<nav
    class="border--default flex h-14 items-center justify-between border-b bg-white px-4 dark:bg-zinc-950">
    <div class="flex flex-wrap items-center">
      @if (sidebarCollapsed()) {
        <button (click)="toggleSidebar()" aria-label="Open sidebar">
          <fa-icon
            [icon]="['fas', 'arrow-right-from-bracket']"
            class="text--hover-highlight transform--rotate rotate-180 text-xl"></fa-icon>
        </button>
      } @else {
        <button (click)="toggleSidebar()" aria-label="Close sidebar">
          <fa-icon
            [icon]="['fas', 'arrow-right-from-bracket']"
            class="text--hover-highlight transform--rotate text-xl"></fa-icon>
        </button>
      }
      <app-breadcrumb />
    </div>

    <div class="flex items-center gap-x-4">
      <app-theme-switch />
      <app-nav-menu class="self-center" />
    </div>
  </nav> `,
})
export class NavbarComponent {
  /**
   * INPUTS
   */
  sidebarCollapsed = input(true);

  /**
   * OUTPUTS
   */
  sidebarToggleEvent = output<boolean>();

  toggleSidebar() {
    this.sidebarToggleEvent.emit(!this.sidebarCollapsed);
  }
}
