import { FeatureKey } from '@core/feature-preview/types/feature-key';

export enum FeaturePreviewStatus {
  HIDDEN,
  PREVIEW,
  LIVE,
}

export type FeaturePreview = {
  key: FeatureKey;
  name: string;
  description: string;
  status: FeaturePreviewStatus;
};
