import { createReducer, on } from '@ngrx/store';
import { initialAuthState } from '@core/states/auth/auth.state';
import * as AuthActions from '@core/states/auth/auth.actions';
import { LoadStatus } from '@core/states/auth/interfaces/load-status';

export const authReducers = createReducer(
  initialAuthState,
  on(AuthActions.setUser, (state, { user }) => ({
    ...state,
    user,
    error: undefined,
  })),
  on(AuthActions.setLoadStatus, (state, { loadStatus }) => ({
    ...state,
    loadStatus,
    error: undefined,
  })),
  on(AuthActions.setError, (state, { error }) => ({
    ...state,
    error,
    user: undefined,
    loadStatus: LoadStatus.ERROR,
  })),
  on(AuthActions.removeUser, state => ({
    ...state,
    user: undefined,
    error: undefined,
    loadStatus: LoadStatus.NOT_LOADED,
  }))
);
