import { FeatureKey } from '@core/feature-preview/types/feature-key';
import {
  FeaturePreview,
  FeaturePreviewStatus,
} from '@core/feature-preview/types/feature-preview';

export const Features: { [k in FeatureKey]: FeaturePreview } | undefined = {
  Dashboard: {
    key: 'Dashboard',
    name: 'Dashboard',
    description: 'Will enable dashboard feature',
    status: FeaturePreviewStatus.PREVIEW,
  },
  Workflow: {
    key: 'Workflow',
    name: 'Workflow',
    description:
      'Will replace process area with a new workflow area to group process definitions',
    status: FeaturePreviewStatus.PREVIEW,
  },
};
