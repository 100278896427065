import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureKey } from '@core/feature-preview/types/feature-key';
import { isFeatureEnabled } from '@core/feature-preview/is-feature-enabled';

@Directive({
  standalone: true,
  selector: '[appIsFeatureEnabled]',
})
export class IsFeatureEnabledDirective {
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  @Input() set appIsFeatureEnabled(key: FeatureKey) {
    if (isFeatureEnabled(key)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
